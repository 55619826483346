<template>
  <div>
    <h3 class="py-3">
      Filter by :
    </h3>
    <v-row>
      <v-col md="4">
        <v-select
          v-model="form.branch_id"
          :items="branches"
          :label="$t('settings.branches')"
          item-text="name"
          item-value="id"
          outlined
          dense
          @input="doFilter()"
        />
      </v-col>
      <v-col md="4">
        <v-select
          v-model="form.category_id"
          :items="categories"
          :label="$t('settings.categories')"
          item-text="name"
          item-value="id"
          outlined
          dense
          @input="doFilter()"
        />
      </v-col>
      <v-col md="4">
        <v-text-field
          v-model="form.label"
          outlined
          :label="$t('filter.label')"
          required
          dense
          @input="doFilter()"
        />
      </v-col>
      <v-col md="6">
        <v-select
          v-model="form.status_id"
          :items="status"
          :label="$t('filter.status')"
          item-text="name"
          item-value="id"
          outlined
          dense
          @input="doFilter()"
        />
      </v-col>
      <v-col md="6">
        <v-select
          v-model="form.metal_type"
          :items="metalTypes"
          :label="$t('filter.metalType')"
          item-text="name"
          item-value="id"
          outlined
          dense
          @input="doFilter()"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { ServiceFactory } from '@/services/ServiceFactory'
  const branchesServices = ServiceFactory.get('branches')
  const categoryServices = ServiceFactory.get('category')
  const mainStockServices = ServiceFactory.get('mainStock')
  const stoneServices = ServiceFactory.get('stones')

  export default {
    data: (vm) => ({
      branches: [],
      categories: [],
      codes: [],
      status: [],
      metalTypes: [],
      form: {
        branch_id: null,
        category_id: null,
        label: null,
        status_id: null,
        metal_type: null,
      },
    }),
    created () {
      this.fetchAllBranches()
      this.fetchAllcategories()
      this.fetchAllStatus()
      this.fetchMetalTypes()
    },
    methods: {
      doFilter () {
        this.$emit('doFilter', this.form)
      },
      async fetchAllBranches () {
        this.dataLoading = true
        const list = await branchesServices.getActiveList()
        this.branches = list.data
      },
      async fetchAllcategories () {
        this.dataLoading = true
        const list = await categoryServices.getActiveList()
        this.categories = list.data
      },
      async fetchAllStatus () {
        this.dataLoading = true
        const listStatus = await mainStockServices.getStatus()
        this.status = listStatus.data
      },
      async fetchMetalTypes () {
        this.dataLoading = true
        const list = await stoneServices.getMetalTypes()
        this.metalTypes = list.data
      },
    },
  }
</script>
