<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-card>
      <v-card-text>
        <filterComponents
          @doFilter="setFilter"
        />
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>
        {{ $t('clients.clientsHistory') }}
        <v-spacer />
      </v-card-title>
      <v-data-table
        :loading="dataLoading"
        :headers="headers"
        :search="search"
        :items="list"
        :items-per-page="20"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50]
        }"
        :options.sync="options"
        :server-items-length="total"
        :page-count="numberOfPages"
        @fetchAllItems="fetchAllItems"
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-chip
            v-if="item.action === 'Purchase'"
            color="success"
            text-color="white"
          >
            {{ item.action }}
          </v-chip>
          <v-chip
            v-else-if="item.action === 'Refund'"
            color="yellow"
            text-color="black"
          >
            {{ item.action }}
          </v-chip>
          <v-chip
            v-else
            color="red"
            text-color="black"
          >
            {{ item.action }}
          </v-chip>
        </template>
        <template v-slot:[`item.paymentStatus`]="{ item }">
          <v-chip
            v-if="item.receipt.payment_status === 1"
            color="success"
            text-color="white"
          >
            Completed
          </v-chip>
          <v-chip
            v-else
            color="red"
            text-color="white"
          >
            UnCompleted
          </v-chip>
        </template>
        <template
          v-slot:no-data
          loading
        >
          <p>{{ $t('actions.noData') }}</p>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar
      v-model="successSnackbar"
      color="success"
      shaped
      bottom
      right
      :timeout="timeout"
    >
      {{ successMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      color="red"
      shaped
      bottom
      right
      :timeout="timeout"
    >
      {{ errorMessage }}
    </v-snackbar>
  </v-container>
</template>
<script>
  import filterComponents from './filter.vue'
  import { ServiceFactory } from '@/services/ServiceFactory'
  const clientsService = ServiceFactory.get('clients')
  export default {
    name: 'Companies',
    components: {
      filterComponents,
    },
    data: (vm) => ({
      search: '',
      dataLoading: false,
      page: 0,
      total: 0,
      numberOfPages: 0,
      options: {},
      list: [],
      Roles: [],
      filter: {
        branch_id: null,
        category_id: null,
        label: null,
        status_id: null,
        metal_type: null,
      },
      loading: false,
      deleteDailog: false,
      itemDetails: {},
      editedIndex: -1,
      successSnackbar: false,
      errorSnackbar: false,
      timeout: 3000,
      successMessage: '',
      errorMessage: '',
      disabled: false,
      headers: [
        {
          text: vm.$t('settings.id'),
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: vm.$t('clients.clientHistory.clientName'), sortable: false, value: 'client.name' },
        { text: vm.$t('clients.clientHistory.code'), sortable: false, value: 'product.label' },
        { text: vm.$t('clients.clientHistory.productStatus'), sortable: false, value: 'action' },
        { text: vm.$t('clients.clientHistory.paidDate'), sortable: false, value: 'receipt.receipt_date' },
        { text: vm.$t('clients.clientHistory.amount'), sortable: false, value: 'amount' },
        { text: vm.$t('clients.clientHistory.paymentStatus'), sortable: false, value: 'paymentStatus' },
        { text: vm.$t('clients.clientHistory.remainingMoney'), sortable: false, value: 'receipt.reseller_id' },

      ],
    }),
    watch: {
      options: {
        handler () {
          this.fetchAllItems()
        },
      },
    },
    methods: {
      setFilter (value) {
        this.filter = value
        this.fetchAllItems()
      },
      async fetchAllItems () {
        this.dataLoading = true
        const { page, itemsPerPage } = this.options
        const pageNumber = page - 1
        const list = await clientsService.getAllclientsHistory(itemsPerPage, page, pageNumber, this.filter)
        this.list = list.data
        this.total = list.total
        // this.numberOfPages = companies.data.pageCount
        this.dataLoading = false
      },
    },
  }
</script>
<style>
a{
  text-decoration: none;
}
</style>
